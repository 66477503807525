:root {
  --main-bg-color: #28282c;
  --main-text-color: #ffffff;
  --highlighted-text-color: #3f9ab7;

  --primary-btn-bg-color: #3f9ab7;
  --primary-btn-bg-color-hover: #31768c;

  --primary-btn-color: #fff;

  --nav-btn-bg-color: #383940;
  --nav-btn-color: #3f9ab7;
  --nav-btn-bg-color-hover: #28282c;

  --thirty: #1f2023;

  --dark: #28282c;
  --icon: #fff;

  --switch-theme-bg: #3f9ab7;
  --switch-theme-font: #ffffff;
  --switch-theme-icon: #ffffff;

  --form-bg: #1f2023;

  --border-color: #44434b;
}
