@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Mulish&display=swap');
@import url('https://fonts.googleapis.com/css?family=Jura&display=swap');
@import url('https://fonts.googleapis.com/css?family=Mulish');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Mulish';
  }
}

body {
  font-size: 16px;
  font-family: 'Mulish';
}

.font-smallest {
  font-size: 12px;
}

.font-small {
  font-size: 14px;
}

.font-normal {
  font-size: 16px;
}

.font-large {
  font-size: 18px;
}

.font-extraLarge {
  font-size: 21px;
}

.font-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.font-uppercase {
  text-transform: uppercase;
}

.font-highlighted {
  color: var(--highlighted-text-color);
}

.font-dark-blue {
  color: var(--dark-blue);
}

.font-light-blue {
  color: var(--light-blue);
}

.font-orange {
  color: var(--orange);
}

.font-weight-600 {
  font-weight: 600;
}

.font-footer-text {
  font-weight: 300;
}
