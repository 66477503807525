html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  width: 100%;
  background: var(--main-bg-color);
  color: var(--main-text-color);
}

#root {
  height: 100%;
  width: 100%;
  margin: auto;
}

a {
  text-decoration: none;
  color: var(--main-text-color);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #11171a;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tips {
  color: var(--highlighted-text-color);
}

.cookie {
  width: 75% !important;
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: fixed;
  right: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.switchtheme {
  position: fixed;
  bottom: 30px;
  left: 20px;
  z-index: 9999;
  background-color: var(--switch-theme-bg);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: var(--switch-theme-font);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  gap: 10px;
  transition: 0.4s;

  .icon {
    fill: var(--switch-theme-icon);
  }

  .icon-stroke {
    stroke: var(--switch-theme-icon);
  }

  &:hover {
    transform: scale(1.1);
    transition: 0.4s;
  }
}

.icon {
  fill: var(--icon);
}

.icon-stroke {
  stroke: var(--icon);
}

.dark-bg {
  background-color: var(--dark);
}

.highlighted-text {
  font-weight: 900 !important;
  color: var(--highlighted-text-color) !important;
}

.design-object {
  position: absolute;
  right: 10px;
  top: 20%;
  // opacity: 0.5;
}

.design-object-left {
  position: absolute;
  left: 5%;
  top: 80%;
  opacity: 0.6;
  z-index: -10;
}

.design-object-circle {
  position: absolute;
  right: -100%;
  top: 20%;
  z-index: -10;
  // opacity: 0.5;
}
